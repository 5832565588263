import Swiper from 'swiper'
import { Navigation, Pagination, Controller, Grid } from 'swiper/modules'
import global from 'site/global'
function QuickThumbs(sel, doRandomInitialSlide=true, maxTotal=4, thumbType='', spaceBetween= 20) {
    this.sel = sel
    this.quickThumbsSwiper = null
    this.self = this
    this.doRandomInitialSlide = doRandomInitialSlide
    this.thumbType = thumbType
    this.spaceBetween = spaceBetween
    this.maxTotal = Number(maxTotal)
    this.id = null

    this.resize = this.resize.bind(this);
    $(window).on( "resize", this.resize);
}

QuickThumbs.prototype.init = function() {
    let t = this
    let initialSlide = 0
    if (this.doRandomInitialSlide) {
        let totalCount = $(this.sel + " .swiper-slide").length
        initialSlide = Math.floor(Math.random() * totalCount)
    }

    t.id = $(this.sel).attr('data-dots-id')

    let maxTotalMid = (this.maxTotal>1) ? 2 : 1
    let isSide = $(this.sel).hasClass('side');

    let maxTotalOverride = this.maxTotal
    //let peeker = 0.6
    let peeker = 0


    //4 and 3 breakpoints
    let breakpointsJson = {
        // when window width is >= Xpx
        0: {
            slidesPerView: 1+peeker,
            slidesPerGroup: 1+peeker,
        },
        550: {//$breakpoint-quickthumbs
            slidesPerView: (maxTotalOverride < 2) ? maxTotalOverride +peeker: 2+peeker,
            slidesPerGroup: (maxTotalOverride < 2) ? maxTotalOverride +peeker: 2+peeker
        },
        900: {
            slidesPerView: (maxTotalOverride < 3) ? maxTotalOverride +peeker: 3+peeker,
            slidesPerGroup: (maxTotalOverride < 3) ? maxTotalOverride +peeker: 3+peeker
        },
        1100: {
            slidesPerView: (maxTotalOverride < 4) ? maxTotalOverride +peeker: 4+peeker,
            slidesPerGroup: (maxTotalOverride < 4) ? maxTotalOverride +peeker: 4+peeker
        },
        1300: {
            slidesPerView: (maxTotalOverride < 5) ? maxTotalOverride +peeker: 5+peeker,
            slidesPerGroup: (maxTotalOverride < 5) ? maxTotalOverride +peeker: 5+peeker
        }
    }

    this.quickThumbsSwiper = new Swiper(this.sel + " .quick-thumbs-swiper", {
        speed: 1000,
        effect: 'slide',
        modules: [Navigation, Pagination],
        init: false,
        loop: false,
        initialSlide: 0,
        // loopedSlides: 10,
        spaceBetween: this.spaceBetween,
        slidesPerView: maxTotalOverride+peeker,
        slidesPerGroup: maxTotalOverride,
        //slidesPerGroup: 1,
        slideToClickedSlide: false,
        centeredSlides: false,
        watchSlidesVisibility: true,
        watchSlidesProgress: true,
        touchMoveStopPropagation: true,
        mousewheelControl: false,
        //navigation: {
        //    nextEl: this.sel + " .swiper-button-next",
        //    prevEl: this.sel + " .swiper-button-prev",
        //},
        //pagination: {
        //    enabled:true,
        //    clickable: true,
        //    el: this.sel + "-dots .dots",
        //},
        breakpoints: breakpointsJson
    })
    this.quickThumbsSwiper.on('init', function(a) {
        t.markFirstVisibleSlide(t)
        t.moreSlideHider(a, t)

        global.refreshGtagLinks()
    });
    this.quickThumbsSwiper.on('beforeInit', function(a) {
        t.markFirstVisibleSlide(t)
    });
    this.quickThumbsSwiper.on('slideChangeTransitionEnd', function(a) {
        t.markFirstVisibleSlide(t)
        t.moreSlideHider(a, t)
    });
    this.quickThumbsSwiper.on('resize', function(a) {
        t.moreSlideHider(a, t)
    });

    if (this.quickThumbsSwiper) {
        this.quickThumbsSwiper.init()
    }

}

QuickThumbs.prototype.moreSlideHider = function(a, t) {
    if (a.slides?.length > a.visibleSlides?.length) {
        $(t.sel).addClass('more-slides')
        $('.quick-thumbs-side-next-'+t.id).addClass('more-slides')
        $('.quick-thumbs-side-prev-'+t.id).addClass('more-slides')
    } else {
        $(t.sel).removeClass('more-slides')
        $('.quick-thumbs-side-next-'+t.id).removeClass('more-slides')
        $('.quick-thumbs-side-prev-'+t.id).removeClass('more-slides')
    }

    // show previous
    if (a.isEnd) {
        $('.quick-thumbs-side-next-'+t.id).css('display','none')
        $('.quick-thumbs-side-prev-'+t.id).css('display','flex')
    }
    // show next
    if (a.isBeginning) {
        $('.quick-thumbs-side-next-'+t.id).css('display','flex')
        $('.quick-thumbs-side-prev-'+t.id).css('display','none')
    }
    // in middle, show both
    if (!a.isEnd && !a.isBeginning) {
        $('.quick-thumbs-side-next-'+t.id).css('display','flex')
        $('.quick-thumbs-side-prev-'+t.id).css('display','flex')
    }
    // is both, aka no more slides, show none
    if (a.isEnd && a.isBeginning) {
        $('.quick-thumbs-side-next-'+t.id).css('display','none')
        $('.quick-thumbs-side-prev-'+t.id).css('display','none')
    }

}

//////////////////////////////////////////////////////////////////////////////////////////
QuickThumbs.prototype.resize = function(evt) {
    let swiper = this.quickThumbsSwiper
    let slidesCount = swiper.slides.length;
    let isWideMode = window.innerWidth >= 768; // Adjust this for your breakpoint
    let visibleSlides = isWideMode ? 4 : 2; // Number of slides visible in wide and narrow modes

    if (slidesCount > visibleSlides && !swiper.loopedSlides) {
        //swiper.loopCreate();
    } else if (slidesCount <= visibleSlides && swiper.loopedSlides) {
        //swiper.loopDestroy();
    }
}

QuickThumbs.prototype.markFirstVisibleSlide = function(t) {
    let swiper = t.quickThumbsSwiper
    swiper.slides.forEach(slide => {
        slide.classList.remove('first-visible');
    });

    // Find the first 'swiper-slide-visible' and add 'first-visible' class
    const firstVisibleSlide = swiper.slides.find(slide => slide.classList.contains('swiper-slide-visible'));
    if (firstVisibleSlide) {
        firstVisibleSlide.classList.add('first-visible');
    }

    setTimeout(function() {
        $(t.sel).addClass('init')
    },500)
    //$(t.sel).addClass('init')

}

export default QuickThumbs